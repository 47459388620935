import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import Header from "../Components/Header";
import Kyc from "./Kyc";
import Commission from "./Commission";
import SlideImages from "./SlideImages";
import LudoClassicCard from "../Components/LudoClassicCard";
import LudoPopularCard from "../Components/LudoPopularCard";
import LudoQuickCard from "../Components/LudoQuickCard";
import OwlCarousel from "./carousel/OwlCarousel";
// import ludoClassicImg from "../../assets/images/homescreen/ludoClassicHD.webp";
// import ludoPopularImg from "../../assets/images/homescreen/ludoPopularHD.webp";
// import ludoQuickImg from "../../assets/images/homescreen/ludoQuickHD.webp";
import ludoClassicImg from "../../assets/images/homescreen/try1.PNG";
import ludoPopularImg from "../../assets/images/homescreen/try2.PNG";
import ludoQuickImg from "../../assets/images/homescreen/try3.PNG";
import Swal from "sweetalert2";

const Landing = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      // window.location.reload()
    }
    role();
    fetchData();
  }, []);

  const [post, setPost] = useState([]);
  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessage").then((response) => {
      console.log("Message from admin is :- ", response);
      setPost(response.data);
      // console.log(setPost)
    });
    axios.get(baseUrl + "getmessage2").then((response) => {
      console.log("Message2 from admin is :- ", response);
      setPostsecond(response.data);
    });
  }, []);
  console.log("Usssseeerrrrrrrreeee is, ", userAllData);
  return (
    <>
      <div>
        {/* <Commission /> */}
        <div
          className="leftContainer"
          style={{
            height: "38px",
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "99999",
          }}
        >
          {postsecond.length > 0 ? (
            <marquee
              className=" w-100"
              style={{
                // background: "#dc3545",
                background: "#0a075e",
                // background: "#f7a172",

                textAlign: "left",
              }}
              direction="left"
              scrollamount="4"
            >
              {postsecond.map((ele) => (
                <p
                  className="message-text mb-0 p-2 text-light fw-bold"
                  style={{
                    fontWeight: "900",
                    // color: "#664D03 !important",
                    // color: "black !important",
                    fontSize: "12.4px",
                    fontFamily: "Poppins",
                  }}
                >
                  {ele.message}
                </p>
              ))}
            </marquee>
          ) : null}
        </div>
        <div className="landing-header">
          <Header user={userAllData} />
          {/* <div
            style={{
              color: "black",
              border: "2px solid green",
              height: "38px",
            }}
          ></div> */}
        </div>
        {access_token ? (
          <div
            className="leftContainer"
            style={{ height: "100%", overflow: "hidden" }}
          >
            <div className="main-area mt-1">
              <div
                className="height-responsive"
                // style={
                //   userAllData
                //     ? { height: "110vh", overflow: "hidden" }
                //     : {
                //         height: "120vh",
                //         overflow: "hidden",
                //       }
                // }
              >
                <section className="games-section custom-game-section">
                  <div className="">
                    <div className="games-section-headline">
                      <div className="games-window d-block ">
                        {/* marquee text  section  */}
                        {/* {postsecond.length > 0 ? (
                          <marquee
                            className="marqueebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              textAlign: "left",
                            }}
                            direction="left"
                            scrollamount="4"
                          >
                            {postsecond.map((ele) => (
                              <p
                                className="message-text mb-0 p-3 text-dark "
                                style={{
                                  fontWeight: "900",
                                  color: "#664D03",
                                  fontSize: "16.4px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {ele.message}
                              </p>
                            ))}
                          </marquee>
                        ) : null} */}

                        {/* sliding images section */}
                        <SlideImages />
                        {/* <OwlCarousel /> */}

                        {/* message section  */}

                        {post.length > 0 ? (
                          <div
                            className="messagebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              // margin: "8px",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {post.map((element) => (
                                <p
                                  className=" message-text mb-0 p-3"
                                  style={{
                                    fontWeight: "900",
                                    color: "#664D03",
                                    fontSize: "14.4px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {element.message}
                                </p>
                              ))}
                            </p>
                          </div>
                        ) : null}

                        {/* KYC section */}
                        <Kyc />

                        <div className="pt-3 d-flex justify-content-between mb-4 ">
                          <p
                            className="fs-2 text-dark fw-bold "
                            style={{
                              fontFamily: "Poppins",
                              letterSpacing: "2px",
                            }}
                          >
                            Games
                          </p>
                          <button
                            className="guide-btn d-flex "
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBottom"
                            aria-controls="offcanvasBottom"
                            style={{ alignItems: "center" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              class="me-2"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                            Guide
                          </button>
                        </div>
                        <div className="container">
                          <div className="row">
                            <h5 className="text-dark">Popular Games</h5>
                            {/* ludo classic design */}
                            <div className="col-12  p-0">
                              {/* <LudoClassicCard /> */}

                              <Link
                                className="gameCard-container position-relative"
                                to={
                                  userAllData?.verified == "verified"
                                    ? `/Homepage/Ludo%20Classics`
                                    : ""
                                }
                                onClick={() => {
                                  if (userAllData?.verified !== "verified") {
                                    Swal.fire({
                                      title:
                                        "Please complete your KYC first. Access to play is restricted until the process is completed",
                                      icon: "warning",
                                      confirmButtonText: "OK",
                                    });
                                  }
                                }}
                              >
                                <picture className="pl-1">
                                  <img
                                    // className="w-100"
                                    style={{
                                      borderRadius: "8px",
                                      width: "98%",
                                    }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Images/homescreen/ludoClassicHD.PNG"
                                    }
                                    alt=""
                                  />
                                </picture>
                              </Link>
                            </div>

                            <h5 className="text-dark mt-4">Other Games</h5>
                            {/* ludo popular */}
                            <div className="col-6  p-0 ">
                              <Link
                                className="gameCard-container position-relative"
                                to={`#`}
                              >
                                <picture className="pl-1 position-relative">
                                  <img
                                    // className="w-100"
                                    style={{
                                      borderRadius: "8px",
                                      // border: "2px solid blue",
                                      width: "94%",
                                    }}
                                    // src={ludoPopularImg}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Images/homescreen/ludoPopularHD.PNG"
                                    }
                                    alt=""
                                  />
                                </picture>
                              </Link>
                            </div>

                            {/* ludo quick */}
                            <div className="col-6  p-0  ">
                              <Link className=" gameCard-container " to={`#`}>
                                <picture
                                  className=""
                                  // style={{
                                  //   width: "300px",
                                  //   border: "2px solid #088595",
                                  //   padding: "1px",
                                  // }}
                                >
                                  <img
                                    // className="w-"
                                    style={{
                                      borderRadius: "8px",
                                      // border: "2px solid #088595",
                                      width: "94%",
                                      marginLeft: "4px",
                                      // height: "60%",
                                    }}
                                    // src={ludoQuickImg}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Images/homescreen/ludoQuickHD.PNG"
                                    }
                                    alt=""
                                  />
                                  {/* <p
                                    className="liveOrCommingSoon text-black font-weight-bold position-absolute rounded-pill bg-white p-1"
                                    style={{ zIndex: 1 }}
                                  >
                                    Coming Soon
                                  </p> */}
                                </picture>
                              </Link>
                              {/* <LudoQuickCard /> */}
                            </div>

                            {/* snake and ladders */}
                            {/* <div className="col-6  text-center p-0">
                              <Link
                                className="gameCard-container position-relative"
                                to={`#`}
                              >
                                <picture className="">
                                  <img
                                    className="w-100"
                                    style={{ borderRadius: "10px" }}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Images/LandingPage_img/ludo_rummy.c8f10fb97f30572bb265.png"
                                    }
                                    alt=""
                                  />
                                  <p
                                    className="liveOrCommingSoon text-black font-weight-bold position-absolute rounded-pill bg-white p-1"
                                    style={{ zIndex: 1 }}
                                  >
                                    Coming Soon
                                  </p>
                                </picture>
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section className="footer pt-4">
                <div
                  className="position-fixed d-flex align-items-end justify-content-end hstack gap-2"
                  style={{
                    alignItems: "center",
                    zIndex: "10",
                    bottom: "60px",
                    left: "15px",
                    right: "20px",
                    maxWidth: "440px",
                  }}
                >
                  <Link
                    className=" d-flex shadow   align-items-center justify-content-center bg-success"
                    to="/contact-us"
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="3em"
                      height="3em"
                      viewBox="0 0 256 258"
                    >
                      <defs>
                        <linearGradient
                          id="logosWhatsappIcon0"
                          x1="50%"
                          x2="50%"
                          y1="100%"
                          y2="0%"
                        >
                          <stop offset="0%" stop-color="#1faf38" />
                          <stop offset="100%" stop-color="#60d669" />
                        </linearGradient>
                        <linearGradient
                          id="logosWhatsappIcon1"
                          x1="50%"
                          x2="50%"
                          y1="100%"
                          y2="0%"
                        >
                          <stop offset="0%" stop-color="#f9f9f9" />
                          <stop offset="100%" stop-color="#fff" />
                        </linearGradient>
                      </defs>
                      <path
                        fill="url(#logosWhatsappIcon0)"
                        d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a123 123 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                      />
                      <path
                        fill="url(#logosWhatsappIcon1)"
                        d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z"
                      />
                      <path
                        fill="#fff"
                        d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561s11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716s-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                      />
                    </svg>
                  </Link>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <div className="leftContainer leftcontainer-mobile-fixed  ">
            <div className="main-area">
              <div className="collapseCard-container  p-0">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="collapseCard bg-danger p-0">
                      <div
                        className="collapseCard-header"
                        style={{
                          left: "22px",
                          transition: "left 0.3s ease 0s",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="height-overflow border">
                <section className="games-section pt-2">
                  <div className=" rounded-3  ">
                    <div className="games-section-headline pb-1 ">
                      <div className="games-window d-block text-center">
                        {/* marquee text  section  */}
                        {postsecond.length > 0 ? (
                          <marquee
                            className="marqueebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              textAlign: "left",
                            }}
                            direction="left"
                            scrollamount="4"
                          >
                            {postsecond.map((ele) => (
                              <p
                                className="message-text mb-0 p-3 text-dark "
                                style={{
                                  fontWeight: "900",
                                  color: "#664D03",
                                  fontSize: "16.4px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {ele.message}
                              </p>
                            ))}
                          </marquee>
                        ) : null}
                        <Link
                          className="gameCard-container "
                          to={`/Homepage/Ludo%20Classics`}
                        >
                          <picture className="">
                            <img
                              className="w-100 resposive-height-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/LandingPage_img/ludofrontimg.jpg"
                              }
                              alt=""
                            />
                          </picture>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="footer">
                  <div>
                    <p>
                      This Game involves an element of financial risk and may be
                      addictive please play responsibly and at your own risk .
                    </p>
                  </div>
                </section>

                <section className="footer">
                  <div
                    className="position-fixed d-flex align-items-center justify-content-center hstack gap-2"
                    style={{
                      alignItems: "center",
                      zIndex: "10",
                      bottom: "5px",
                      left: "15px",
                      right: "20px",
                      maxWidth: "440px",
                      bottom: "29px",
                    }}
                  >
                    <div className="d-grid flex-grow-1">
                      <Link to="/login">
                        <button className="playnow-btn btn-lg fw-semibold">
                          {" "}
                          Play Now
                        </button>
                      </Link>
                    </div>
                    <Link
                      className="bg-primary shadow-lg rounded-circle d-flex align-items-center justify-content-center text-white"
                      to="/support"
                      style={{ height: "60px", width: "60px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="30"
                        height="30"
                        fill="currentColor"
                      >
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
                      </svg>
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Landing;
