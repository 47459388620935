import React, { useState, useEffect } from "react";
import "./OwlCarousel.css";
import img1 from "../../../assets/images/slider/11.png";
import img2 from "../../../assets/images/slider/222.png";
import img3 from "../../../assets/images/slider/11.png";

function OwlCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      image: img1,
      caption: "Slide 1",
    },
    {
      image: img2,
      caption: "Slide 2",
    },
   
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000); // Change slides every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container">
      <div className="carousel">
        <div className="carousel-inner">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentSlide ? "active" : ""}`}
            >
              <img src={slide.image} alt={slide.caption} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OwlCarousel;
