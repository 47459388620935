import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/layout.css";
import "../css/login.css";
import loginss from "./sss.png";
import Header from "../Components/Header";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
export default function Login() {
  const history = useHistory();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(true);
  const [counter, setCounter] = useState(0);
  const [secretCode, setSecretCode] = useState();
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );

  const handleClick = async (e) => {
    e.preventDefault();
    setCounter(15);
    setTimeout(() => {
      setisButtonDisabled(false);
    }, 15000);
    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else if (phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please  enter correct phone number",
      });
    } else {
      setLoading(true);
      await axios
        .post(baseUrl + `login`, {
          phone,
          referral,
        })
        .then((respone) => {
          setLoading(false);
          if (respone.data.status === 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status === 200) {
            setOtp(true);
            console.log(respone.data);
            setSecretCode(respone.data.secret);
            if (respone.data.myToken) {
              Swal.fire({
                icon: "success",
                title: "OTP",
                text: "Demo Account Testing OTP: " + respone.data.myToken,
              });
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        });
    }
  };

  const resendClick = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "success",
      title: "OTP resend",
      text: "Successfully",
    });

    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else if (phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please  enter correct phone number",
      });
    } else {
      setLoading(true);
      await axios
        .post(baseUrl + `login`, {
          phone,
          referral,
        })
        .then((respone) => {
          setLoading(false);
          if (respone.data.status === 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status === 200) {
            setOtp(true);
            console.log(respone.data);
            setSecretCode(respone.data.secret);
            if (respone.data.myToken) {
              Swal.fire({
                icon: "success",
                title: "OTP",
                text: "Demo Account Testing OTP: " + respone.data.myToken,
              });
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        });
    }
  };

  const varifyOtp = async (e) => {
    e.preventDefault();
    console.log("verify otp submit req");
    if (!phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else {
      setLoading(true);
      await axios
        .post(baseUrl + `login/finish`, {
          phone,
          twofactor_code,
          referral,
          secretCode,
        })
        .then((respone) => {
          setLoading(false);
          if (respone.data.status === 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
            console.log("error first");
          } else if (respone.data.status === 200) {
            const token = respone.data.token;
            localStorage.setItem("token", token);
            // document.getElementById("hello").click()
            window.location.href = "/";
            console.log("error second");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
            console.log("error third");
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.error("Server error:", error);
        });
    }
  };

  const setError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Number",
      confirmation: true,
    });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <>
      <div className="leftContainer">
        <div className="main-area">
          <div style={{ overflowY: "hidden" }}>
            <div
              className="position-absolute w-100 center-xy mx-auto"
              style={{ paddingTop: "85px", zIndex: 0, paddingInline: "10px" }}
            >
              <div className="card w-100" style={{ border: "1px solid #ccc" }}>
                <div className="card-header p-2">
                  <div
                    className="text-center text-black"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Login
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="bg-white cxy flex-column"
                    style={{
                      width: "100%",
                      height: "60px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="text-left w-100">
                      <label
                        className="text-left m-text"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Mobile Number
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1">
                        <i
                          style={{ color: "#000" }}
                          className="fa-solid fa-phone"
                        ></i>
                      </span>
                      <input
                        style={{
                          padding: "20px",
                          backgroundColor: "#fff",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                        className="form-control"
                        name="mobile"
                        type="tel"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          if (e.target.value.length > 10) {
                            setError(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="login-agree">
                      By Continuing, you agree to our{" "}
                      <Link to={"/term-condition"}>Legal Terms</Link> and you
                      are 18 years or older.
                    </p>
                  </div>
                  {otp && (
                    <div
                      className="bg-white p-0 cxy flex-column"
                      style={{
                        width: "100%",
                        height: "60px",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        className="input-group mb-2"
                        style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                      >
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              backgroundColor: "#e9ecef",
                              border: "1px solid #d8d6de",
                              fontFamily: "Poppins",
                            }}
                          >
                            OTP
                          </div>
                        </div>
                        <input
                          className="form-control placeholder-edit"
                          name="password"
                          type="tel"
                          maxLength={6}
                          placeholder="Enter OTP"
                          onChange={(e) => settwofactor_code(e.target.value)}
                          style={{
                            padding: "20px",
                            backgroundColor: "#fff",
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderColor: "#ccc",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {!otp && (
                    <button
                      className="Login-button w-100 cxy mt-4"
                      onClick={(e) => handleClick(e)}
                      style={{ padding: "8px" }}
                    >
                      {loading ? "loading..." : "GET OTP"}
                    </button>
                  )}
                  {otp && (
                    <>
                      <button
                        disabled={isButtonDisabled}
                        className="resend-button cxy mt-4"
                        onClick={(e) => resendClick(e)}
                        style={{ padding: "8px" }}
                      >
                        Resend OTP {(counter != 0 && counter) || ""}
                      </button>
                      <button
                        className="Login-button cxy mt-4"
                        onClick={(e) => varifyOtp(e)}
                        style={{ padding: "8px" }}
                      >
                        {loading ? "Loading..." : "Verify"}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
