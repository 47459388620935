import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import "../css/gamehistory.css";
import Rightcontainer from "../Components/Rightcontainer";
import cancel from "../../assets/images/history_icons/cancel.png";

const Gamehistory = ({ showRoomCodeSearch, data1, isPropData }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;

  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [game, setGame] = useState([]);
  // console.log("gamehistory data",)
  const limit = 500;

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const response = await axios.get(baseUrl + `me`, { headers });
      setUser(response?.data);
      Allgames(response.data._id, pageNumber, limit);
    } catch (error) {
      // Handle errors
    }
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const Allgames = async (id) => {
    if (isPropData) {
      const gameHis = data1?.filter((ele) =>
        [
          "completed",
          "cancelled",
          "conflict",
          "Hold Credited",
          "Win Credited",
          "running",
          "pending",
        ].includes(ele?.status)
      );

      setGame(gameHis || []);
    } else {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      try {
        const response = await axios.get(
          `${baseUrl}game/history/user/${id}?page=${
            pageNumber + 1
          }&_limit=${limit}&roomCode=${searchTerm}`,
          { headers }
        );

        const gameHis = response?.data?.data?.filter((ele) =>
          [
            "completed",
            "cancelled",
            "conflict",
            "Hold Credited",
            "Win Credited",
            "running",
            "pending",
          ].includes(ele?.status)
        );
        console.log(" gamehiss user----", gameHis);
        console.log("response of gamehiss", response.data);

        setGame(gameHis || []);
        setNumberOfPages(response.data.totalPages || 0);
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  useEffect(() => {
    let isMounted = true;

    const cleanupFunction = () => {
      isMounted = false;
    };

    role();

    return cleanupFunction;
  }, [pageNumber, data1]);

  useEffect(() => {
    let isMounted = true;

    const cleanupFunction = () => {
      isMounted = false;
    };

    Allgames(user?._id);

    return cleanupFunction;
  }, [pageNumber, user, baseUrl]);

  return (
    <>
      <div>
        <div
          className="leftContainer"
          style={{ height: "100%", marginBottom: "100px" }}
        >
          {showRoomCodeSearch ? (
            <div className="d-flex gap-2 align-items-center pb-3 transition-history-input">
              <input
                className="w-100"
                type="text"
                placeholder="Enter Room code"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button onClick={() => Allgames(user?._id)}>Search</button>
            </div>
          ) : (
            ""
          )}
          {/* game-cards */}
          {game &&
            game
              .filter((item) => {
                const normalizedSearchTerm = searchTerm?.toLowerCase();
                const currentUserID = user?._id;
                return (
                  (item?.acceptedBy &&
                    item?.acceptedBy._id === currentUserID) ||
                  (item?.createdBy && item?.createdBy._id === currentUserID) ||
                  ((item.status === "Hold Credited" ||
                    item.status === "Win Credited") &&
                    item?.roomCode
                      ?.toLowerCase()
                      .includes(normalizedSearchTerm))
                );
              })
              .map((card) => {
                // console.log("Mapped data at games:- ", card);

                // to hide hold balance entries
                // if (
                //   card?.status === "cancelled" ||
                //   card?.status === "running" ||
                //   card?.status === "pending" ||
                //   card?.status === "conflict" ||
                //   (card?.winner?._id === user?._id &&
                //     card?.status === "completed")
                // ) {
                //   return;
                // }

                if (
                  card?.winner?._id !== user?._id &&
                  card?.status === "Win Credited"
                ) {
                  return;
                }

                let myDate = new Date(2024, 9, 18);
                let dataDate = new Date(card.createdAt);
                let isDataNewer = true;
                if (myDate > dataDate) {
                  isDataNewer = false;
                }

                return (
                  <div
                    className="px-1 py-2 w-100"
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    <div className="d-flex align-items-stretch w-100">
                      <div
                        className=" d-flex flex-column align-items-start justify-content-center border-end "
                        // style="width: 80px;"
                        style={{ width: "80px" }}
                      >
                        <span>
                          <img src={cancel} alt="/" />
                        </span>
                        <span
                          className="text-start"
                          style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                        >
                          {dateFormat(card.createdAt).split(",")[0]}
                        </span>
                        <span
                          className="text-start"
                          style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                        >
                          {dateFormat(card.createdAt).split(",")[1]}
                        </span>
                      </div>
                      <div className="d-flex flex-column align-items-stretch justify-content-center flex-1 w-100 px-2">
                        <div className="d-flex align-items-stretch justify-content-between">
                          <div className="d-flex gap-1">
                            <span
                              className="fw-semibold text-capitalize text-start"
                              // style="font-size: 0.9rem;"
                              style={{
                                fontSize: "0.9rem",
                                fontFamily: "Poppins",
                              }}
                            >
                              {/* {card.status === "completed"
                                ? card.winner && card.winner._id === user?._id
                                  ? "Won Against"
                                  : "Lost Against"
                                : card.status} */}
                              {isDataNewer
                                ? card?.winner &&
                                  card?.winner?._id === user?._id &&
                                  card?.status === "Win Credited"
                                  ? `Won Against ${
                                      card?.acceptedBy &&
                                      card?.acceptedBy?._id === user?._id
                                        ? card?.createdBy?.name
                                        : card?.acceptedBy?.name
                                    } `
                                  : card?.winner?._id === user?._id
                                  ? "Hold Balance"
                                  : card?.status === "cancelled" ||
                                    card?.status === "running" ||
                                    card?.status === "pending" ||
                                    card?.status === "conflict"
                                  ? `Hold Balance`
                                  : card?.status === "Hold Credited"
                                  ? "Game Cancelled"
                                  : `Lost Against ${
                                      card?.acceptedBy &&
                                      card?.acceptedBy?._id === user?._id
                                        ? card?.createdBy?.name
                                        : card?.acceptedBy?.name
                                    }`
                                : card.status === "completed"
                                ? card.winner && card.winner._id === user?._id
                                  ? "Won Against"
                                  : "Lost Against"
                                : card.status}

                              {/* ({card?.winner &&
                              card?.winner?._id === user?._id &&
                              card?.status === "Win Credited"
                                ? `Won Against ${
                                    card?.acceptedBy &&
                                    card?.acceptedBy?._id === user?._id
                                      ? card?.createdBy?.name
                                      : card?.acceptedBy?.name
                                  } `
                                : card?.winner?._id === user?._id
                                ? "Hold Balance"
                                : card?.status === "cancelled"
                                ? `Hold Balance`
                                : card?.status === "Hold Credited"
                                ? "Game Cancelled"
                                : `Lost Against ${
                                    card?.acceptedBy &&
                                    card?.acceptedBy?._id === user?._id
                                      ? card?.createdBy?.name
                                      : card?.acceptedBy?.name
                                  }`}) */}
                            </span>
                            {!isDataNewer && (
                              <span
                                className="fw-semibold text-capitalize text-start"
                                style={{
                                  fontSize: "0.8rem",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {card?.acceptedBy &&
                                card?.acceptedBy?._id === user?._id
                                  ? card?.createdBy?.name
                                  : card?.acceptedBy?.name}
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-end justify-content-center">
                            <span
                              className=" text-end fw-bold my-1"
                              // style="font-size: 0.8rem;"
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                                color: "#198754",
                              }}
                            >
                              {/* {card.status === "completed" && (
                                <>
                                  <div className="text-danger">
                                    {card.losestatus}
                                  </div>
                                  <div className="text-success">
                                    {card.winstatus}
                                  </div>
                                </>
                              )} */}
                              {/* {card.status === "completed" &&
                                (card.winner?._id === user?._id ? (
                                  <span style={{ color: "#198754" }}>
                                    {" "}
                                    (+) {card.winAmount}{" "}
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    (-) {card.gameAmount}{" "}
                                  </span>
                                ))} */}
                              {isDataNewer ? (
                                (card.winner?._id === user?._id &&
                                  card?.status === "Win Credited") ||
                                card?.status === "Hold Credited" ? (
                                  <span style={{ color: "#198754" }}>
                                    {" "}
                                    (+){" "}
                                    {card?.status !== "Hold Credited"
                                      ? card.winAmount
                                      : card.gameAmount}{" "}
                                  </span>
                                ) : card.status !== "cancelled" ? (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    (-) {card.gameAmount}{" "}
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    (-) {card.gameAmount}{" "}
                                  </span>
                                )
                              ) : (
                                card.status === "completed" &&
                                (card.winner?._id === user?._id ? (
                                  <span style={{ color: "#198754" }}>
                                    {" "}
                                    (+) {card.winAmount}{" "}
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    (-) {card.gameAmount}{" "}
                                  </span>
                                ))
                              )}
                              {/* {(card.winner?._id === user?._id &&
                                card?.status === "Win Credited") ||
                              card?.status === "Hold Credited" ? (
                                <span style={{ color: "#198754" }}>
                                  {" "}
                                  (+){" "}
                                  {card?.status !== "Hold Credited"
                                    ? card.winAmount
                                    : card.gameAmount}{" "}
                                </span>
                              ) : card.status !== "cancelled" ? (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  (-) {card.gameAmount}{" "}
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  (-) {card.gameAmount}{" "}
                                </span>
                              )} */}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            className="text-start text-capitalize"
                            style={{
                              fontSize: "0.7rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            Room Code: {card.roomCode}
                          </span>

                          <span
                            className="text-start"
                            style={{
                              fontSize: "0.7rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            closing balance :
                            {/* {card.winner?._id === user?._id
                                ? card.winnerClosingBalance
                                : card.loserClosingBalance} */}
                            {card.createdBy && card.createdBy._id === user?._id
                              ? card.Creator_closingbalance
                              : card.acceptedBy &&
                                card.acceptedBy._id === user?._id
                              ? card.Acceptor_closingbalance
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

          {/* pagination */}
          {/* <div className="">
          <div className="mt-5">
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination justify-content-center custom-pagination-container"
              }
              pageClassName={"page-item custom-page-item"}
              pageLinkClassName={"page-link custom-page-link"}
              previousClassName={"page-item custom-previous-item"}
              previousLinkClassName={"page-link custom-previous-link"}
              nextClassName={"page-item custom-next-item"}
              nextLinkClassName={"page-link custom-next-link"}
              breakClassName={"page-item custom-break-item"}
              breakLinkClassName={"page-link custom-break-link"}
              activeClassName={"active custom-active"}
            />
          </div>
        </div> */}
          {game &&
            game.length === 0 &&
            (showRoomCodeSearch ? (
              <div className="text-center">
                <img
                  src={process.env.PUBLIC_URL + "/Images/refer_null.png"}
                  alt="no data"
                  className="img-fluid"
                  style={{ marginTop: "25%" }}
                />
              </div>
            ) : (
              ""
            ))}
        </div>

        <div className="rightContainer">
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};

export default Gamehistory;
