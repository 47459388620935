import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import "../css/kyc.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import css from "../css/Pan.module.css";
import Swal from "sweetalert2";
import "../css/Loader.css";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Kyc2 = ({ user }) => {
  const history = useHistory();

  const [frontLoaded, setfrontLoaded] = useState(null);
  const [backLoaded, setbackLoaded] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [DOB, setDob] = useState();
  const [process, setProcess] = useState(false);

  const [isKycAuto, setIsKycAuto] = useState(false);
  const [isAddharOtpSent, setIsAddharOtpSent] = useState(false);
  const [aadharOTP, setAadharOTP] = useState("");

  let aadharProcess = useRef(false);

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setWebsiteSettings(data);
    setIsKycAuto(data.autoKyc);
  };

  // to handle kyc automatic or manual
  // const handelKycMode = (kycmode) => {

  //   if (kycmode === "manual") {
  //     setIsKycAuto();
  //   } ;
  // }

  const handleSubmitdata = (e) => {
    if (user.verified == "unverified") {
      if (aadharProcess.current === false) {
        setProcess(true);
        aadharProcess.current = true;
        e.preventDefault();
        console.log(frontLoaded, backLoaded);
        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("dob", DOB);
        formData.append("front", frontLoaded);
        formData.append("back", backLoaded);

        if (frontLoaded && backLoaded) {
          const access_token = localStorage.getItem("token");
          const headers = {
            Authorization: `Bearer ${access_token}`,
          };
          console.log("formdata", formData);
          axios
            .post(baseUrl + `aadharcard`, formData, { headers })
            .then((res) => {
              console.log(res.data);

              if (res.data.msg === false) {
                Swal.fire({
                  title: "Duplicate Entity",
                  icon: "danger",
                  confirmButtonText: "error",
                });
              } else {
                Swal.fire({
                  title: "You Kyc form submitted",
                  icon: "success",
                  confirmButtonText: "ok",
                });
                history.push("/Profile");
              }
              // console.log(res.data)
              //
              aadharProcess.current = false;
              setProcess(false);
            })
            .catch((e) => {
              if (e.response.status == 400) {
                Swal.fire({
                  title: `${e.response.data.msg}`,
                  icon: "warning",
                  confirmButtonText: "ok",
                }).then(() => {
                  aadharProcess.current = false;
                  setProcess(false);
                  return;
                });
              }
              if (e.response.status == 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("token");
                window.location.reload();
                history.push("/login");
              }
            });
        } else {
          aadharProcess.current = false;
          setProcess(false);
          alert("please fill all field");
        }
      } else {
        alert("You have submitted request already.");
      }
    } else {
      alert("You request in Process.");
    }
  };
  const sendAadharOtp = async () => {
    console.log("aadhar number is ", number);
    if (!/^\d{12}$/.test(number)) {
      alert("Enter 12 digit aadhar number");
      return;
    } else if (user.verified !== "unverified") {
      alert("You are under process");
      return;
    }

    try {
      setProcess(true);
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      let response = await axios.post(
        baseUrl + "initiate-kyc",
        { aadharNumber: number },
        { headers }
      );

      console.log("auto kyc response is :- ", response);
      setProcess(false);
      setIsAddharOtpSent(true);
    } catch (e) {
      console.log(e);
      setProcess(false);
      Swal.fire({
        title: `${e.response.data.message}`,
        icon: "warning",
        confirmButtonText: "ok",
      });
    }
    setProcess(false);
  };

  // function for autokycdata
  const handleAutoSubmitKyc = async (e) => {
    e.preventDefault();
    if (!isAddharOtpSent) {
      await sendAadharOtp();
      return;
    } else {
      console.log("No i am sending otp ", number, aadharOTP);
      if (aadharOTP.length < 4) {
        alert("Enter valid otp");
        return;
      }

      try {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${access_token}`,
        };

        let response = await axios.post(
          baseUrl + "verify-otp",
          { aadharNumber: number, otp: aadharOTP },
          { headers }
        );

        console.log("auto kyc response is :- ", response);
        setProcess(false);
        //  setIsAddharOtpSent(true);
        history.push("/Profile");
      } catch (e) {
        console.log(e);

        // if (
        //   e.response.data.message.toLowerCase() ==
        //   "request failed with status code 400"
        // ) {
        //   Swal.fire({
        //     title: `Check you Otp once again`,
        //     icon: "warning",
        //     confirmButtonText: "ok",
        //   }).then(() => {
        //     setProcess(false);
        //     return;
        //   });
        // }

        Swal.fire({
          title: `Error Ocuured: Please check your OTP once again. If it is correct, try again later`,
          icon: "warning",
          confirmButtonText: "ok",
        }).then(() => {
          setProcess(false);
          return;
        });
        setProcess(false);
      }
      setProcess(false);
    }
  };
  useEffect(() => {
    // fetch website setting data
    fetchData();
    console.log(user);
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    // const frontPhoto = document.getElementById("frontPhoto");
    // frontPhoto.onchange = (e) => {
    //   const [file] = frontPhoto.files;
    //   setfrontLoaded(file);
    // };
    // const backPhoto = document.getElementById("backPhoto");
    // backPhoto.onchange = (e) => {
    //   const [file] = backPhoto.files;
    //   setbackLoaded(file);
    // };
  }, []);
  return (
    <div>
      <div className="leftContainer ">
        <div className="" style={{ padding: "1rem", paddingTop: "80px" }}>
          <div
            className="d-flex justify-content-between wallet-back"
            style={{ alignItems: "center" }}
          >
            <div>
              <Link to={"/profile"}>
                <button
                  className="d-flex justify-content-between"
                  style={{
                    alignItems: "center",
                    backgroundColor: "#4782F4",
                    padding: "8px",
                    paddingInline: "20px",
                    border: "none",
                    borderRadius: "5px",
                    color: "#fff",
                    fontFamily: "Poppins",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    className="me-2"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                    ></path>
                  </svg>
                  <span
                    className="text-capitalize"
                    style={{ fontWeight: "bold" }}
                  >
                    Back
                  </span>
                </button>
              </Link>
            </div>
            <div>
              <button
                className="guide-btn d-flex "
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
                style={{ alignItems: "center" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="me-2"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                </svg>
                Guide
              </button>
            </div>
          </div>

          <div className="pt-3 mb-5">
            <div className="card" style={{ border: "1px solid #ccc" }}>
              <div
                className="card-header text-center"
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  letterSpacing: "0.3px",
                  fontFamily: "Poppins",
                }}
              >
                KYC
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "4px",
                  }}
                >
                  {WebSitesettings.autoKyc === false && (
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        fontWeight: "normal",
                      }}
                    >
                      {/* <input
                        type="radio"
                        name="kycMode"
                        checked={!isKycAuto}
                        onClick={() => setIsKycAuto(false)}
                      /> */}
                      Manual
                    </div>
                  )}
                  {WebSitesettings.autoKyc === true && (
                    <div
                      style={{
                        display: "flex",
                        gap: "4px",
                        fontWeight: "normal",
                      }}
                    >
                      {/* <input
                        type="radio"
                        name="kycMode"
                        checked={isKycAuto}
                        onClick={() => setIsKycAuto(true)}
                      /> */}
                      Automatic
                    </div>
                  )}
                </div>
              </div>

              <div className="card-body">
                {WebSitesettings.autoKyc === false && (
                  <form onSubmit={handleSubmitdata}>
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicName"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter Name
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter name"
                        type="text"
                        name="name"
                        id="formBasicName"
                        className="form-control aadharcard"
                        value={name}
                        title="Name should not be longer than 20 characters"
                        maxlength="20"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicAadhar"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter Aadhar Number
                      </label>
                      <input
                        placeholder="Enter Aadhar Card Number"
                        type="text"
                        maxLength={12}
                        name="name"
                        className="form-control aadharcard"
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => setNumber(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicEmail"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter your email
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter email"
                        type="text"
                        name={email}
                        id="formBasicEmail"
                        className="form-control aadharcard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className="mb-3 d-flex flex-column align-items-start">
                    <label
                      className="form-label"
                      for="formBasicDob"
                      style={{ color: "#212529", fontFamily: "Poppins" }}
                    >
                      Enter DOB
                    </label>
                    <input
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                      }}
                      placeholder="Enter DOB"
                      type="date"
                      name="name"
                      id="formBasicDob"
                      className="form-control aadharcard"
                      value={DOB}
                      onChange={(e) => setDob(e.target.value)}
                      required
                    />
                  </div> */}
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicFront"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Front Image
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="No file chosen"
                        id="formBasicFront"
                        className="form-control aadharcard"
                        name="frontPhoto"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setfrontLoaded(e.target.files[0])}
                        required
                      />
                    </div>
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicBack"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Back Image
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="No file chosen"
                        id="formBasicBack"
                        className="form-control aadharcard"
                        name="backPhoto"
                        type="file"
                        accept="image/*"
                        onChange={(e) => setbackLoaded(e.target.files[0])}
                        required
                      />
                    </div>
                    <p
                      className="text-center"
                      style={{ fontSize: "0.9rem", fontFamily: "Poppins" }}
                    >
                      By Continuing, you agree to our
                      <Link
                        to="/term-condition"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                        }}
                        href=""
                      >
                        {" "}
                        Legal Terms
                      </Link>{" "}
                      and you are 18 years or older.
                    </p>
                    <div className="d-grid py-4">
                      <button
                        type="submit"
                        className={`btn p-1 ${process ? "disabled" : ""}`}
                        style={{
                          backgroundColor: "rgb(8, 83, 223)",
                          borderRadius: "5px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Poppins",
                            color: "#fff",
                            fontSize: "1.2rem",
                          }}
                        >
                          {" "}
                          {process ? "Loading..." : "Request for KYC"}
                        </span>
                      </button>
                    </div>
                  </form>
                )}
                {WebSitesettings.autoKyc === true && (
                  <form onSubmit={handleAutoSubmitKyc}>
                    {/* <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicName"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter Name
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter name"
                        type="text"
                        name="name"
                        id="formBasicName"
                        className="form-control aadharcard"
                        value={name}
                        title="Name should not be longer than 20 characters"
                        maxlength="20"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicEmail"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter your email
                      </label>
                      <input
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        placeholder="Enter email"
                        type="text"
                        name={email}
                        id="formBasicEmail"
                        className="form-control aadharcard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div> */}
                    <div className="mb-3 d-flex flex-column align-items-start">
                      <label
                        className="form-label"
                        for="formBasicAadhar"
                        style={{ color: "#212529", fontFamily: "Poppins" }}
                      >
                        Enter Aadhar Number
                      </label>
                      <input
                        placeholder="Enter Aadhar Card Number"
                        type="text"
                        maxLength={12}
                        name="name"
                        className="form-control aadharcard"
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "5px",
                        }}
                        readOnly={isAddharOtpSent}
                        disabled={isAddharOtpSent}
                        onChange={(e) => setNumber(e.target.value)}
                        required
                      />
                    </div>

                    {/* otp sent to your aadhar registered number message */}
                    {isAddharOtpSent && (
                      <p
                        style={{
                          color: "green",
                          fontSize: "14px",
                          textAlign: "justify",
                        }}
                      >
                        An OTP has been sent to your Aadhar-registered mobile
                        number. Please use the OTP to complete your verification
                        process.
                      </p>
                    )}

                    {isAddharOtpSent && (
                      <div className="mb-3 d-flex flex-column align-items-start">
                        <label
                          className="form-label"
                          for="formBasicAadhar"
                          style={{ color: "#212529", fontFamily: "Poppins" }}
                        >
                          Enter OTP
                        </label>
                        <input
                          placeholder="Enter Your OTP"
                          type="text"
                          maxLength={6}
                          name="name"
                          className="form-control aadharcard"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => setAadharOTP(e.target.value)}
                          required
                        />
                      </div>
                    )}
                    <p
                      className="text-center"
                      style={{ fontSize: "0.9rem", fontFamily: "Poppins" }}
                    >
                      By Continuing, you agree to our
                      <Link
                        to="/term-condition"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Poppins",
                        }}
                        href=""
                      >
                        {" "}
                        Legal Terms
                      </Link>{" "}
                      and you are 18 years or older.
                    </p>
                    <div className="d-grid py-4">
                      <button
                        type="submit"
                        className={`btn p-1 ${process ? "disabled" : ""}`}
                        style={{
                          backgroundColor: "rgb(8, 83, 223)",
                          borderRadius: "5px",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "Poppins",
                            color: "#fff",
                            fontSize: "1.2rem",
                          }}
                        >
                          {" "}
                          {process
                            ? "Loading..."
                            : isAddharOtpSent
                            ? "Complete KYC "
                            : "Send OTP"}
                        </span>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>

          {/* <div className="kycPage mt-5 py-4 px-3"> */}
          {/* <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
              You need to submit a document that shows that you are{" "}
              <span style={{ fontWeight: 500 }}>above 18 years</span> of age and
              not a resident of{" "}
              <span style={{ fontWeight: 500 }}>
                Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh,
                Tamil Nadu and Karnataka.
              </span>
            </p>
           
            <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
              Enter details of Aadhar Card:{" "}
            </p> */}
          {/* <form onSubmit={handleSubmitdata}>
              <div style={{ marginTop: "10px" }}>
                <div className="kyc-doc-input">
                  <div className="label">First Name</div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="kyc-doc-input mt-4">
                  <div className="label">Email Id</div>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="kyc-doc-input mt-4">
                  <div className="label">Date of Birth</div>
                  <input
                    type="date"
                    name="name"
                    placeholder="enter name"
                    value={DOB}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
                <div className="kyc-doc-input mt-4">
                  <div className="label">Aadhar Number</div>
                  <input
                    type="text"
                    maxLength={12}
                    name="name"
                    placeholder=" Aadhar Number"
                    onChange={(e) => setNumber(e.target.value)}
                    required
                  />
                </div>
                <div className={`${css.doc_upload} mt-4`}>
                  <input
                    id="frontPhoto"
                    name="frontPhoto"
                    type="file"
                    accept="image/*"
                    required
                  />
                  {!frontLoaded && (
                    <div className="cxy flex-column position-absolute ">
                      <img
                        src="/images/file-uploader-icon.png"
                        width="17px"
                        alt=""
                        className="snip-img"
                      />
                      <div className={`${css.sideNav_text} mt-2`}>
                        Upload front Photo of your Aadhar Card.
                      </div>
                    </div>
                  )}
                  {frontLoaded && (
                    <div className={css.uploaded}>
                      <img
                        src="/images/file-icon.png"
                        width="26px"
                        alt=""
                        style={{ marginRight: "20px" }}
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ width: "80%" }}
                      >
                        <div className={css.name}>{frontLoaded.name}</div>
                        <div className={css.size}>
                          {(frontLoaded.size / 1024 / 1024).toFixed(2)} MB
                        </div>
                      </div>
                      <div className="image-block">
                        <img
                          src="/images/global-cross.png"
                          width="10px"
                          alt=""
                          onClick={() => setfrontLoaded(null)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${css.doc_upload} mt-4`}>
                  <input
                    id="backPhoto"
                    name="backPhoto"
                    type="file"
                    accept="image/*"
                    required
                  />
                  {!backLoaded && (
                    <div className="cxy flex-column position-absolute ">
                      <img
                        src="/images/file-uploader-icon.png"
                        width="17px"
                        alt=""
                        className="snip-img"
                      />
                      <div className={`${css.sideNav_text} mt-2`}>
                        Upload back Photo of your Aadhar Card.
                      </div>
                    </div>
                  )}
                  {backLoaded && (
                    <div className={css.uploaded}>
                      <img
                        src="/images/file-icon.png"
                        width="26px"
                        alt=""
                        style={{ marginRight: "20px" }}
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ width: "80%" }}
                      >
                        <div className={css.name}>{backLoaded.name}</div>
                        <div className={css.size}>
                          {(backLoaded.size / 1024 / 1024).toFixed(2)} MB
                        </div>
                      </div>
                      <div className="image-block">
                        <img
                          src="/images/global-cross.png"
                          width="10px"
                          alt=""
                          onClick={() => setbackLoaded(null)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                
              </div>
              <div style={{ paddingBottom: "25%" }} />
              <div className="refer-footer p-0">
             
                <button
                  type="submit"
                  className="w-100 btn-success bg-success"
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "1em",
                    fontWeight: "700",
                    height: "48px",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  submit
                </button>
              </div>
            </form> */}
          {/* </div> */}
          {/* {Boolean(process) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src={"https://ludo57.in/Images/LandingPage_img/loader1.gif"}
                style={{ width: "100%" }}
              />
            </div>
          )} */}
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Kyc2;
