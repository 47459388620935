import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import slideImage1 from "../../assets/images/slider/11.png";
import slideImage2 from "../../assets/images/slider/222.png";
import axios from "axios";
import "./SlideImages.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const SlideImages = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const [imagesArrFromApi, setImagesArrFromApi] = useState([
    slideImage1,
    slideImage2,
  ]);
  console.log("imagesArrFromApi", imagesArrFromApi);
  const getSliderImgFromApi = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const response = await axios.get(`${baseUrl}slideimg-get`, { headers });
      console.log("Data from slider images are ", response.data);
      setImagesArrFromApi(response.data[0].images || []);
    } catch (error) {
      console.log("Error fetching slider images:", error);
    }
  };
  useEffect(() => {
    // Uncomment the below line to fetch images from API
    getSliderImgFromApi();
  }, []);
  return (
    imagesArrFromApi &&
    imagesArrFromApi.length > 0 && (
      <div
        className="rounded-0 slider-container mt-2 mb-2"
        style={{
          border: "2px solid gray",
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          speed={800}
          // navigation
          // pagination={{ clickable: true }}
        >
          {imagesArrFromApi.map((val, index) => {
            const regex =
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:\d+)?(\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
            return (
              <SwiperSlide key={index}>
                <a
                  // href={regex.test(val.clickUrl) ? val.clickUrl : null}
                  href={val.clickUrl ? val.clickUrl : null}
                  target="_blank"
                  style={{
                    marginRight: "0px !important",
                    padding: "0px !important",
                  }}
                  className="rounded-1"
                >
                  <div className=" rounded-1 position-relative">
                    <img
                      // src={val}
                      src={baseUrl + `public/${val.filename}`}
                      className=" d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                    {/* <img
                    // src={val}
                    src={val}
                    className="rounded-1 d-block w-100"
                    alt="First slide"
                  /> */}
                    {/* click button */}
                    {/* {val.clickUrl && (
                    <div
                      style={{
                        position: "absolute",
                        right: "2px",
                        bottom: "2px",
                        fontSize: "16px",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "4px",
                        background: "white",
                      }}
                    >
                      <a
                        href={val.clickUrl ? val.clickUrl : null}
                        target="_blank"
                      >
                        Click Me
                      </a>
                    </div>
                  )} */}
                  </div>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    )
  );
};
export default SlideImages;
